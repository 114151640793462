import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../utilities/Loading';
import Posts from '../components/Posts';
import MySkillsContainer from '../components/my-skills/MySkillsContainer'
import '../Styles/pages-style/home.css';
import { motion } from 'framer-motion';
import CTA from '../utilities/CTA';
import Typewriter from '../utilities/Typewriter';
import PostCard from '../components/PostCard';



const Home = ({ restBase, featuredImage }) => {
  const navigate = useNavigate();
  const restPath = restBase + 'pages?slug=home&acf_format=standard';
  const [restData, setData] = useState([]);
  const [isLoaded, setLoadStatus] = useState(false);
  const mainRef = useRef();
  const btnGetToKnowMe = "Click to know me better";
  const btnViewProjects = "Click to view all my projects";
  const btnReadMore = "Click to read more articles";
  const [specificPostsData, setSpecificPostsData] = useState([]); // Fetching Spec "Project" Posts

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(restPath);
        if (response.ok) {
          const data = await response.json();
          setData(data[0]);
          setLoadStatus(true);
        } else {
          setLoadStatus(false);
        }
      } catch (error) {
        console.error('Error:', error);
        setLoadStatus(false);
      }
    };
    fetchData();
  }, [restPath]);

  const firstElement = useRef(null);
  const lastElement = useRef(null);

  useEffect(() => {
    if (isLoaded) {
      const focusableElements = 'button, [href], input, select, textarea, [tabIndex]:not([tabIndex="-1"])';
      const focusable = Array.from(document.querySelectorAll(focusableElements));
      firstElement.current = focusable[0];
      lastElement.current = focusable[focusable.length - 1];
    }
  }, [isLoaded]);

  const handleKeyDown = (e) => {
    if ((e.shiftKey && e.key === 'Tab' && document.activeElement === firstElement.current) || (!e.shiftKey && e.key === "Tab" && document.activeElement === lastElement.current)) {
      e.preventDefault();
      e.shiftKey ? lastElement.current.focus() : firstElement.current.focus();
    }
  };

  useEffect(() => {
    if (isLoaded) {
      const focusableElements = 'button, [href], input, select, textarea, [tabIndex]:not([tabIndex="-1"])';
      const focusable = Array.from(document.querySelectorAll(focusableElements));
      firstElement.current = focusable[0];
      lastElement.current = focusable[focusable.length - 1];
    }
  }, [isLoaded]);

  /* Fetching Specific Posts from the Category "Project" */
  useEffect(() => {
    const projectCategoryId = 4; 
    const specificPostIds = [277, 271];
    const fetchSpecificPostsFromCategory = async () => {
      try {
        const response = await fetch(`${restBase}posts?categories=${projectCategoryId}&include=${specificPostIds.join(",")}&_embed`);

        if (response.ok) {
          const data = await response.json();
          console.log("Fetched data:", data);  
          setSpecificPostsData(data);
        } else {
          console.error("Error with response:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching specific posts:", error);
      }
    };
    fetchSpecificPostsFromCategory();
  }, [restBase]);

  return (
    <>
      {isLoaded ? (
        <div className='home-page'>
          <header role="banner" className="header-bg">
            <div className="header-content">
              <motion.div
                className="container image-container"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.4, duration: 1 }}
                aria-hidden="true"
              >
                <img src={restData.acf.header_poster} alt="Illustration of Home Page" className="responsive-image" />
              </motion.div>

              <motion.div
                className="intro-container"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.7, duration: 1 }}
                aria-hidden="true"
              >
                <div 
                  className="entry-content" 
                  dangerouslySetInnerHTML={{ __html: restData?.acf?.header_intro }}
                  role="document"
                  tabIndex="0"
                >
                </div>
              </motion.div>
            </div>
          </header>

          <main ref={mainRef} role="main" aria-label={restData.title.rendered} onKeyDown={handleKeyDown}>
            <section className="area-of-expertise" id="area-of-expertise" aria-label="Area of Expertise">
              <h2 className="section-title">Area of Expertise</h2>
              <div className="entry-content area-expertise-content" dangerouslySetInnerHTML={{ __html: restData?.acf?.expertises_intro }} tabIndex="0"></div>
              <MySkillsContainer/>
              <button 
                className="area-expertise-btn"
                aria-label={btnGetToKnowMe} 
                title={btnGetToKnowMe}
                onClick={() => navigate("/about")}
              >
                Get To Know Me!
              </button>
            </section>

            <section className="fwd-projects" id="fwd-projects" aria-label="Web Development Projects">
              <h2>Web Development Projects</h2>
              <div className="entry-content" dangerouslySetInnerHTML={{ __html: restData?.acf?.projects_intro }}></div>
              <div className="fwd-projects-container">
                {Array.isArray(specificPostsData) && specificPostsData.map((post, index) => (
                  <PostCard key={post.id} post={post} index={index} />
                ))}
              </div>
              <button 
                aria-label={btnViewProjects} 
                title={btnViewProjects}
                onClick={() => navigate("/portfolio")}
              >
                View All Projects
              </button>
            </section>

            <section className="cto-st" aria-label="Call to Action Section">
              <h2>
                <div className="typewriter-container">
                  <Typewriter words={["Connect With Me !"]} loop={true} />
                </div>
              </h2>
              <div className="entry-content" dangerouslySetInnerHTML={{ __html: restData?.acf?.cto_intro }}></div>
              {
                restData?.acf?.lets_connect && <CTA restData={restData} />
              }
            </section>

            <section className="blog-st" aria-label="Blog Section">
              <h2>Recent Articles</h2>
              <div className="entry-content blog-intro" dangerouslySetInnerHTML={{ __html: restData?.acf?.blog_intro }}></div>
              <Posts restBase={restBase} featuredImage={featuredImage} category="7" limit={2} />
              <button 
                aria-label={btnReadMore} 
                title={btnReadMore}
                onClick={() => navigate("/blog")}
              >
                Read More
              </button>
            </section>

            <article>
              <div className="entry-content" dangerouslySetInnerHTML={{ __html: restData.content.rendered }}></div>
            </article>
          </main>
        </div>
      ) : (
        <Loading
          aria-live="polite"
          role="status"
          style={{ color: '#ffffff', backgroundColor: '#000000' }}
        >
          Please wait, we're loading.
        </Loading>
      )}
    </>
  );
};

export default Home;




