import React from 'react';
import Lottie from 'react-lottie-player';

const LottieComponent = ({ animationData, width, height }) => {
  
  return (
    <Lottie 
      play 
      loop 
      animationData={animationData} 
      style={{ width, height }}
      aria-label='Animation of a tiger' 
      alt='Animation of a tiger' 
    />);
};

export default LottieComponent;


