import React from 'react';
import ExperienceCard from './ExperienceCard';
import ArrayOfWorkExperience from '../../assets/arrays/ArrayOfWorkExperience';
import { motion } from 'framer-motion';
import { VerticalTimeline } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const WorkExperience = () => {
  return (
    <>
      <motion.div>
        <h2 className="sectionHeadText">Overview</h2>
      </motion.div>
      <div className="work-experience">
        <VerticalTimeline className="vertical-timeline-custom-line timeline-custom-line">
          {ArrayOfWorkExperience.map((experience, index) => (
            <ExperienceCard key={index} experience={experience} />
          ))}
        </VerticalTimeline>
      </div>
    </>
  );
};

export default WorkExperience;














