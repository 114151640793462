import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import Portfolio from './Pages/Portfolio';
import Blog from './Pages/Blog';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Posts from './components/Posts';
import Post from './components/IndividualPost';
import './App.css';

function App() {

//   const restBase = 'https://massas.sg-host.com/msakoufwd/wp-json/wp/v2/';
  const restBase = 'https://msakou.ca/msakoufwd/wp-json/wp/v2/';

  const featuredImage = ( featuredImageObject ) => {
    let imgWidth = featuredImageObject.media_details.sizes.full.width;
    let imgHeight = featuredImageObject.media_details.sizes.full.height;
    let imgURL = featuredImageObject.source_url;
    let img = `<img src="${imgURL}" 
        width="${imgWidth}"
        height="${imgHeight}"
        alt="${featuredImageObject.alt_text}"
        srcset="${imgURL} ${imgWidth}w,
        ${featuredImageObject.media_details.sizes.large ? featuredImageObject.media_details.sizes.large.source_url + ' 1024w,' : ''}
        ${featuredImageObject.media_details.sizes.medium_large ? featuredImageObject.media_details.sizes.medium_large.source_url + ' 768w,' : ''}
        ${featuredImageObject.media_details.sizes.medium ? featuredImageObject.media_details.sizes.medium.source_url + ' 300w' : ''}"
        sizes="(max-width: ${imgWidth}px) 100vw, ${imgWidth}px">`;

    return {__html: img};
  }

  return (
      <div className="App">
          <Router basename="/">
              <Navbar />
              <main id="main" tabIndex="-1" role="main" aria-label="Content">
              <Routes>
                  <Route path="/" element={<Home restBase={restBase} />} />
                  <Route path="/about" element={<About restBase={restBase} />} />
                  <Route path="/portfolio" element={<Portfolio restBase={restBase} />} />
                  <Route path="/blog" element={<Blog restBase={restBase} />} />
                  <Route path="/portfolio/project" element={<Posts restBase={restBase} category="project" featuredImage={featuredImage} />} />
                  <Route path="/posts" element={<Posts restBase={restBase} category=" " featuredImage={featuredImage} />} />
                  <Route path="/post/:slug" element={<Post restBase={restBase} featuredImage={featuredImage} />} />
                  <Route path="/blog/article" element={<Posts restBase={restBase} category="article" featuredImage={featuredImage} />} />
              </Routes>
              </main>
              <Footer />
          </Router>
      </div>
  );
}

export default App;
























// import React from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import styled, { ThemeProvider } from 'styled-components';
// import Home from './Pages/Home';
// import About from './Pages/About';
// import Portfolio from './Pages/Portfolio';
// import Blog from './Pages/Blog';
// import Footer from './components/Footer';
// import Navbar from './components/Navbar';
// import Posts from './components/Posts';
// import Post from './components/IndividualPost';
// import GlobalStyle from './styled/GlobalStyle';
// import theme from './styled/theme';



// const AppContainer = styled.div`
//   text-align: center;
//   display: flex;
//   flex-direction: column;
//   min-height: 100vh;
//   justify-content: space-between;
// `;


// const Main = styled.main`
//   flex: 1;
// `;


// const AppHeader = styled.header`
//   background-color: ${props => props.theme.primaryColor};
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   height: 70px;
//   padding: 0 30px;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

//   @media (max-width: 640px) {
//     flex-direction: column;
//     align-items: center;
//   }
// `;




// function App() {
//   const restBase = 'https://massas.sg-host.com/msakoufwd/wp-json/wp/v2/';

//   const featuredImage = ( featuredImageObject ) => {
//     let imgWidth = featuredImageObject.media_details.sizes.full.width;
//     let imgHeight = featuredImageObject.media_details.sizes.full.height;
//     let imgURL = featuredImageObject.source_url;
//     let img = `<img src="${imgURL}" 
//         width="${imgWidth}"
//         height="${imgHeight}"
//         alt="${featuredImageObject.alt_text}"
//         srcset="${imgURL} ${imgWidth}w,
//         ${featuredImageObject.media_details.sizes.large ? featuredImageObject.media_details.sizes.large.source_url + ' 1024w,' : ''}
//         ${featuredImageObject.media_details.sizes.medium_large ? featuredImageObject.media_details.sizes.medium_large.source_url + ' 768w,' : ''}
//         ${featuredImageObject.media_details.sizes.medium ? featuredImageObject.media_details.sizes.medium.source_url + ' 300w' : ''}"
//         sizes="(max-width: ${imgWidth}) 100vw, ${imgWidth}px">`;
//     return {__html: img}
//   }

//   return (

//     <ThemeProvider theme={theme}>

//       <GlobalStyle />

//       <AppContainer>
//         <Router basename="/">
//           <AppHeader />
//           <Navbar />
//           <Main id="main" tabIndex="-1" role="main" aria-label="Content">
//             <Routes>
//               <Route path="/" element={<Home restBase={restBase} />} />
//               <Route path="/about" element={<About restBase={restBase} />} />
//               <Route path="/portfolio" element={<Portfolio restBase={restBase} />} />
//               <Route path="/blog" element={<Blog restBase={restBase} />} />
//               <Route path="/portfolio/project" element={<Posts restBase={restBase} category="project" featuredImage={featuredImage} />} />
//               <Route path="/posts" element={<Posts restBase={restBase} category=" " featuredImage={featuredImage} />} />
//               <Route path="/post/:slug" element={<Post restBase={restBase} featuredImage={featuredImage} />} />
//               <Route path="/blog/article" element={<Posts restBase={restBase} category="article" featuredImage={featuredImage} />} />
//             </Routes>
//           </Main>
//           <Footer />
//         </Router>
//       </AppContainer>


//     </ThemeProvider>
//   );
// }

// export default App;





