import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import ProjectBrief from '../components/my-projects/ProjectBrief';
import '../Styles/pages-style/portfolio.css';
import Pagination from '../utilities/Pagination';
import Loading from '../utilities/Loading';
import {
  fadeInOut,
  textVariant,
  // staggerContainer,
  // fadeIn,
} from '../utilities/MyAnimation';

const Portfolio = ({ restBase }) => {
  const [restData, setData] = useState([]);
  const [pageData, setPageData] = useState(null);
  const [isLoaded, setLoadStatus] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPosts, setTotalPosts] = useState(0);
  const per_page = 4;

  useEffect(() => {
    const fetchPageData = async () => {
      try {
        const res = await fetch(`${restBase}pages?slug=portfolio`);
        const data = await res.json();
        setPageData(data[0]);
      } catch (error) {
        console.error('Error fetching data for portfolio page:', error);
      }
    };

    fetchPageData();
  }, [restBase]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${restBase}posts?_embed&per_page=${per_page}&page=${currentPage}`
        );
        if (response.ok) {
          let data = await response.json();
          const specialPostIDs = [271, 277, 284, 290];

          data = data.sort((a, b) => {
            if (specialPostIDs.includes(b.id) && !specialPostIDs.includes(a.id)) {
              return 1;
            } else if (!specialPostIDs.includes(b.id) && specialPostIDs.includes(a.id)) {
              return -1;
            } else {
              return 0;
            }
          });

          setData(data);
          const total = parseInt(response.headers.get('X-WP-Total'), 10);
          setTotalPosts(total);
          setLoadStatus(true);
        } else {
          setLoadStatus(false);
        }
      } catch (error) {
        console.error('Error fetching data for portfolio page:', error);
        setLoadStatus(false);
      }
    };

    fetchData();
  }, [restBase, currentPage, per_page]);

  const totalPages = Math.ceil(totalPosts / per_page);

  let gridClass = '';

  const len = restData.length;

  if (len === 1) {
      gridClass = 'single-article';
  } else if (len === 2) {
      gridClass = 'two-articles';
  } else if (len === 4) {
      gridClass = 'four-articles';
  }

  return (
    <motion.div initial='hidden' animate='show' variants={fadeInOut}>
      {pageData && (
        <div>
          <motion.h1
            variants={textVariant(0)}
            dangerouslySetInnerHTML={{ __html: pageData?.acf?.portfolio_intro }}
            className="margin-class"
          />
          <motion.p
            variants={textVariant(0.5)}
            dangerouslySetInnerHTML={{ __html: pageData?.acf?.paragraph_intro }}
            className="margin-class portfolio_intro_paragraph"
          />
        </div>
      )}
      {isLoaded ? (
        <>
          <motion.div className={`projects-grid ${gridClass}`}>
            {restData.map((post, index) => (
              <ProjectBrief post={post} index={index} key={post.id} />
            ))}
          </motion.div>
          <Pagination currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} />
        </>
      ) : (
        <Loading />
      )}
    </motion.div>
  );
};

export default Portfolio;






