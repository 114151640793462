import React, { useEffect, useState } from 'react';
import Loading from '../utilities/Loading';
import '../Styles/pages-style/about.css';
import WorkExperience from '../components/Work/WorkExperience';
import LottieComponent from '../lottieAnimation/LottieComponent';
import tiger from '../lottieAnimation/tiger.json';



const About = ({ restBase }) => {
  const restPath = `${restBase}pages?slug=about&acf_format=standard`;

  const [restData, setRestData] = useState({});
  const [isLoaded, setLoadStatus] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(restPath);
        if (response.ok) {
          const data = await response.json();
          setRestData(data[0] || {});
          setLoadStatus(true);
        } else {
          setLoadStatus(false);
        }
      } catch (error) {
        console.error(error);
        setLoadStatus(false);
      }
    };
    fetchData();
  }, [restPath]);

  // fade-in sections
  useEffect(() => {

    const fadeInSections = document.querySelectorAll(".fade-in");
  
    if (fadeInSections) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      });
  
      fadeInSections.forEach(section => {
        observer.observe(section);
      });
  
      return () => {
        fadeInSections.forEach(section => {
          observer.unobserve(section);
        });
      };
    }
  
  }, [restData]);
  

  return (
    <div>
      {isLoaded ? (
        <div className="card-container">
          <section aria-label="About me section">
            <h1 id="general-info">{restData.acf?.general_info_title}</h1> 

            <header className="intro-container">
              <div className="image-animation-container">
                <div className="image-slider-container">
                  <LottieComponent 
                    animationData={tiger} // Correct Prop
                    height={400} 
                    width={400} 
                  />
                </div>
              </div>
            </header>

            <div
              id="general-info-section"
              role="region"
              tabIndex={0}
              aria-labelledby="general-info"
            >

              <ul className="content-navigation">
                <li>
                  <a href="#overview">Overview</a>
                </li>
                <li>
                  <a href="#expertise">Technical Acumen</a>
                </li>
                <li>
                  <a href="#work-experience">Work Experience</a>
                </li>
                <li>
                  <a href="#training">Education & Certifications</a>
                </li>
                {/* <li>
                  <a href="#strengths">Strengths, Motivation and Personality Traits</a>
                </li> */}
              </ul>

              {/* This is the INTRO Paragraph */}
              <article id="intro-paragraph" aria-labelledby="intro-paragraph" className="fade-in">
                <div className="entry-content" dangerouslySetInnerHTML={{ __html: restData?.acf?.info_paragraph }}></div>
              </article>

              {/* Here is the OVERVIEW */}
              <article id="overview" aria-labelledby="overview">
                <WorkExperience />
              </article>

              {/* 'Technical Acumen (or Expertise)' Section */}
              <article id="expertise" aria-labelledby="training-heading" className="fade-in">
                <h2 id="expertise-heading">{restData.acf?.area_of_expertises_title}</h2>
                <div className="entry-content" dangerouslySetInnerHTML={{ __html: restData?.acf?.area_of_expertises_paragraph }}></div>
              </article>

              {/* Work Experience */}
              <article id="work-experience" aria-labelledby="work-experience-heading" className="fade-in">
                <h2 id="work-experience-heading">{restData.acf?.work_experience_title}</h2>
                <div className="entry-content" dangerouslySetInnerHTML={{ __html: restData?.acf?.work_experience_paragraph }}></div>
              </article>

              {/* EDUCATION */}
              <article id="training" aria-labelledby="training-heading" className="fade-in">
                <h2 id="training-heading">{restData.acf?.training_title}</h2>
                <div className="entry-content" dangerouslySetInnerHTML={{ __html: restData?.acf?.training_paragraph }}></div>
              </article>

              {/* STRENGTHS */}
              {/* <article id="strengths" aria-labelledby="strengths-heading">
                <h2 id="strengths-heading">{restData.acf?.strengths_title}</h2>
                <div className="entry-content" dangerouslySetInnerHTML={{ __html: restData?.acf?.strengths_paragraph }}></div>
              </article> */}

            </div>
          </section>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default About;




