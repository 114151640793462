import React, { useEffect, useRef } from 'react';
import '../../Styles/my-skills-styles/Skill.css';
import '../../Styles/my-skills-styles/SkillCarouselContainer.css';

import SkillList from "../my-skills/SkillList";

function SkillCarouselContainer() {
  const carouselRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Tab') {
        const carouselElement = carouselRef.current;
        const focusableElements = carouselElement.querySelectorAll('a[href], button, input, select, textarea');

        const firstFocusableElement = focusableElements[0];
        const lastFocusableElement = focusableElements[focusableElements.length - 1];

        if (!event.shiftKey && document.activeElement === lastFocusableElement) {
          firstFocusableElement.focus();
          event.preventDefault();
        } else if (event.shiftKey && document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus();
          event.preventDefault();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className="skill-carousel-container">
      <div className="carousel-wall" role="list" aria-label="Skill Carousel" ref={carouselRef}>
        <div className="carousel-list">
          <SkillList /> {/* Add multiple SkillLists here for continuous looping */}
          <SkillList />
          <SkillList />
        </div>
      </div>
    </div>
  );
}

export default SkillCarouselContainer;










