import SimonsIcon from "../other/simons-icon.png";
import GardaWorldIcon from "../other/garda-icon.png";
import UbysseyIcon from "../other/ubyssey1.jpeg";
import WagonIcon from "../other/wagon4.png";
import ulavalIcon from "../other/ulaval-icon.png";
import bcitIcon from "../other/bcit3.png";
import peakLeader from "../other/BCIT Student Association - BCITSA Peak Leadership   Certified Peak Leader - 2023-05-16.png";

const ArrayOfWorkExperience = [
  {
    job_position: "Volunteer Web Developer",
    company_name: "The UBYSSEY -  Student Newspaper, University of British Columbia",
    company_website: "https://ubyssey.ca/",
    iconBg: UbysseyIcon,
    date: "2023/08/01 - Present"
  },
  {
    job_position: "Front-End Web Developer",
    company_name: " British Columbia Institute of Technology (BCIT)",
    company_website: "https://www.bcit.ca/programs front-end-web-developer-certificate-full-time-6535cert/",
    iconBg: bcitIcon,
    date: "2023/01/07 - 2023/07/21"
  },
  {
    job_position: "Certified Peak Leader",
    company_name: "BCIT Student Association (BCITSA)",
    company_website: "https://ca.badgr.com/public/badges/w-4A1VaZSMygSwc9BzuF9A",
    iconBg: peakLeader,
    date: "2023/05"
  },
  {
    job_position: "Sales Associate",
    company_name: "La Maison Simons",
    company_website: "https://www.simons.ca/en",
    iconBg: SimonsIcon,
    date: "2022/10/01 - Present"
  },
  {
    job_position: "Bilingual (En/Fr) Security Officer",
    company_name: "GardaWorld",
    company_website: "https://www.garda.com/",
    iconBg: GardaWorldIcon,
    date: "2022/06/01 - Present"
  },
  {
    job_position: "Receptionist Volunteer",
    company_name: "ITH Beach Bungalow Surf Hostel San Diego",
    company_website: "https://ithhostels.com/location/pacific-beach-hostel/",
    iconBg: "",
    date: "2021 - 2022"
  },
  {
    job_position: "Web Development Bootcamp",
    company_name: "Le Wagon",
    company_website: "https://www.lewagon.com/web-development-course",
    iconBg: WagonIcon,
    date: "2020 - 2020"
  },
  {
    job_position: "Bachelor of Arts (B.A.)",
    company_name: "Laval University (U.L)",
    company_website: "https://www.ulaval.ca/etudes/programmes/baccalaureat-multidisciplinaire",
    iconBg: ulavalIcon,
    date: "2016/01/04 - 2019/05/31"
  },

];

export default ArrayOfWorkExperience;






// {
//   job_position: "job three",
//   company_name: "company three",
//   company_website: "https://www.google.ca/",
//   iconBg: "",
//   date: "2023/01/07 - 2023/07/21"
// },