import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Loading from '../utilities/Loading';
import '../Styles/pages-style/blog.css';


const Blog = ({ restBase }) => {

  const restPath = `${restBase}posts?_embed&categories=7`;
  // const restPath = `${restBase}posts?_embed&categories=7&acf_format=standard`;
  
  const [restData, setData] = useState([]);
  const [isLoaded, setLoadStatus] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(restPath);
        if (response.ok) {
          const data = await response.json();
          setData(data);
          setLoadStatus(true);
        } else {
          setLoadStatus(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoadStatus(false);
      }
    };

    fetchData();
  }, [restPath]);

  return (
    <div>
      <h1>Articles and Publications</h1>

      {isLoaded ? (
        restData.length !== 0 ? (
          <div className="card-container">
            {restData.map((post, index) => (
              <div key={post.id} id={`post-${post.id}`} className="card" role="article">
                {post._embedded['wp:featuredmedia'] && post._embedded['wp:featuredmedia'][0]?.media_type === 'image' && (
                  <figure className="featured-image" aria-labelledby={`featured-image-label-${post.id}`}>
                    <img
                      src={post._embedded['wp:featuredmedia'][0].source_url}
                      alt={`Featured Image for Post ${post.title.rendered}`}
                      aria-hidden="true"
                    />
                    <figcaption id={`featured-image-label-${post.id}`}>Featured Image</figcaption>
                  </figure>
                )}
                <h2>{post.title.rendered}</h2>
                <div className="entry-content" dangerouslySetInnerHTML={{ __html: post.content.rendered }}></div>
                <Link to={`/post/${post.slug}`} aria-label={`Read more about ${post.title.rendered}`} role="link">
                  Read More
                </Link>
              </div>
            ))}
          </div>
        ) : (
          <p className="no-content-message coming-soon">Coming soon! I'm preparing some insightful content. Stay tuned for updates!</p>
        )
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Blog;