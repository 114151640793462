import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../utilities/Loading';
import '../Styles/component-styles/individualPost.css';



const Post = ({ restBase, featuredImage }) => {
  const { slug } = useParams();
  const [restData, setData] = useState(null);
  const [isLoaded, setLoadStatus] = useState(false);

  useEffect(() => {
    const restPath = `${restBase}posts?slug=${slug}&_embed&acf_format=standard`;

    const fetchData = async () => {
      try {
        const response = await fetch(restPath);
        if (response.ok) {
          const data = await response.json();
          setData(data[0]);
          setLoadStatus(true);
        } else {
          throw new Error('Error fetching data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoadStatus(false);
      }
    };

    fetchData();
  }, [restBase, slug]);

  function createMarkup(content) {
    return {__html: content};
  }

  // fade-in sections
  useEffect(() => {

    const fadeInSections = document.querySelectorAll(".fade-in");
  
    if (fadeInSections) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      });
  
      fadeInSections.forEach(section => {
        observer.observe(section);
      });
  
      return () => {
        fadeInSections.forEach(section => {
          observer.unobserve(section);
        });
      };
    }
  
  }, [restData]);

  return (
    <>
      {isLoaded && restData ? (
        <article className='individualProject' aria-labelledby={"post-title-" + restData.id} id={`post-${restData.id}`}>

          <h1 id={"post-title-" + restData.id}>{restData.title.rendered}</h1>
          <div className="entry-content" dangerouslySetInnerHTML={createMarkup(restData.content.rendered)} />

          <section className="fade-in">
            <h2>Project Overview </h2>
            <div dangerouslySetInnerHTML={createMarkup(restData.acf.project_overview)} />
          </section>

          <figure>
            <img src={restData.acf.project_poster} alt={`Project poster for ${restData.title.rendered}`} />
            <figcaption>{restData.title.rendered}</figcaption>
          </figure>

          <section className="fade-in">
            <a href={restData.acf.project_url} target="_blank" rel="noreferrer" aria-label="Visit project">
              Visit Project (Website)
            </a>
          </section>
          
          <section className="fade-in">
            <h2>Key Responsabilities</h2>
            {typeof restData.acf.key_responsabilities === 'string' ? 
              <div dangerouslySetInnerHTML={createMarkup(restData.acf.key_responsabilities)} /> : null
            }
          </section>

          <section className="fade-in">
            <h2>User Flow</h2>
            {typeof restData.acf.project_description === 'string' ? 
              <div dangerouslySetInnerHTML={createMarkup(restData.acf.project_description)} /> : null
            }
          </section>

          <section className="fade-in">
            <h2>Tools And Technologies Used</h2>
            {Array.isArray(restData.acf.tools_and_technologies_used) &&
              <ul style={{ listStyleType: 'none', padding: 0 }}>
                {restData.acf.tools_and_technologies_used.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            }
          </section>

          <section className="fade-in">
            <a href={restData.acf.github_repository} target="_blank" rel="noreferrer" aria-label="View project on GitHub">
              View Code (on GitHub)
            </a>
          </section>

        </article>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Post;











/*
IndividualPost.js (or Post.js) : I will use this component to display a Project Page

  Purpose: Displays a single post ) in detail (like when you click on a post's title in 'Portfolio page', 'home page', 'blog page' blog and it takes you to the full article). 

  Behavior:
    It fetches detailed information of a specific post using a slug or other unique identifier.
    It renders comprehensive details of the post, including title, full content, and other meta information.

  Key Features:
    Fetching a single post's full details.
    Rendering the full content of the post.
    Displaying additional fields like project_overview, project_description, and so on.
    
*/

