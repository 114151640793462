import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import close from '../assets/other/close.png';
import menu from '../assets/other/menu.png';
import '../Styles/component-styles/navbar.css';
import AvatarAnimation from '../utilities/ImageSlider';


// Constants
const navLinks = [
  { id: 'home', title: 'Home', path: '/' },
  { id: 'about', title: 'About', path: '/about' },
  { id: 'portfolio', title: 'Portfolio', path: '/portfolio' },
  { id: 'blog', title: 'Blog', path: '/blog' },
];

const Navbar = () => {
  // States
  const [active, setActive] = useState('');
  const [toggle, setToggle] = useState(false);
  
  // Hooks
  const navigate = useNavigate();
  const location = useLocation();

  // Effects
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setToggle(false);
        document.body.style.overflowY = 'auto';
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Handler Functions
  const handleNavLinkClick = (path, title) => {
    setActive(title);
    navigate(path);
    setToggle(false);
    window.scrollTo(0, 0);
  };

  const handleToggleClick = () => {
    setToggle((prevState) => !prevState);
    const body = document.body;

    if (!toggle) {
      body.style.overflowY = 'hidden';
    } else {
      body.style.overflowY = 'auto';
    }
  };

  // Component JSX
  return (
    <nav className="navbar" role="navigation" aria-label="Main navigation">
      <h2 className="navbar-title" onClick={() => handleNavLinkClick('/', 'Home')} tabIndex={0}>
        <AvatarAnimation />
      </h2>
      <button
        type="button"
        className="menu-btn"
        onClick={handleToggleClick}
        aria-label="Toggle menu"
        aria-expanded={toggle}
      >
        <img src={menu} alt="menu" />
      </button>
      <ul className={`nav-links-list desktop-nav ${toggle ? 'open' : ''}`}>
        {navLinks.map((nav) => (
          <li key={nav.id} className={`nav-link ${active === nav.title ? 'active' : ''}`}>
            <Link
              to={nav.path}
              className={location.pathname === nav.path ? 'link active' : 'link'}
              onClick={() => handleNavLinkClick(nav.path, nav.title)}
            >
              {nav.title}
            </Link>
          </li>
        ))}
      </ul>
      <div className={`mobile-nav ${toggle ? 'open' : ''}`}>
        <button
          type="button"
          className="close-btn"
          onClick={handleToggleClick}
          aria-label="Close menu"
        >
          <img src={close} alt="close" />
        </button>
        <ul className="mobile-nav-links">
          {navLinks.map((nav) => (
            <li key={nav.id} className={`mobile-nav-link ${active === nav.title ? 'active' : ''}`}>
              <Link
                to={nav.path}
                className={location.pathname === nav.path ? 'mobile-link active' : 'mobile-link'}
                onClick={() => handleNavLinkClick(nav.path, nav.title)}
              >
                {nav.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;


