import React from 'react';
import '../../Styles/my-skills-styles/Skill.css';

function Skill({ skill }) {
  return (
    <div className="skill">
      <a className="skill-icon-box" href={skill.docLink} target="_blank" rel="noopener noreferrer">
        <img className="skill-icon" src={skill.src} alt={skill.alt} />
      </a>
      <span className="skill-name">{skill.name}</span>
    </div>
  );
}

export default Skill;