import React, { useState, useEffect } from 'react';
import Loading from '../utilities/Loading';
import PostCard from './PostCard';


const Posts = ({ restBase, category, limit }) => {
  const restPath = `${restBase}posts?_embed&per_page=${limit}&categories=${category}`;

  const [restData, setData] = useState([]);
  const [isLoaded, setLoadStatus] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(restPath);
        if (response.ok) {
          const data = await response.json();
          setData(data);
          setLoadStatus(true);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoadStatus(false);
      }
    };

    fetchData();
  }, [restPath]);

  return (
    <>
      {isLoaded ? (
        <>
          <main aria-label="List of blog posts">
            {restData.map((post, index) => (
                <PostCard key={post.id} post={post} index={index} />
            ))}
          </main>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Posts;




/*
Posts.js

  Purpose: This component is responsible for fetching and displaying a list of posts (like a blog's main page).

  Behavior:
    It fetches a list of posts based on certain parameters (like a specific category or a limit on the number of posts to retrieve).
    It renders multiple post previews in a list format.

  Key Features:
    Fetching posts using useEffect.
    Displaying a list of posts (by iterating over the fetched data).
    
*/
