import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Tilt from 'react-parallax-tilt';
import { fadeIn, zoomIn } from '../../utilities/MyAnimation';
import '../../Styles/Projects-Styles/proj-brief.css';

const ProjectBrief = ({ post, index }) => {
    return (
        <motion.article
            key={`post-${post.id}`}
            id={`post-${post.id}`}
            className='projectBrief-card'
            role="article"
            variants={{ ...fadeIn('right', 'spring', index * 0.5, 0.75), ...zoomIn(index * 0.5, 0.75) }}
            initial='hidden'
            animate='show'
            tabIndex={0}
        >
            <Tilt
                className="projectBrief-tiltCard"
                options={{
                    max: 40,
                    scale: 1,
                    speed: 450
                }}
            >
                {post._embedded && post._embedded['wp:featuredmedia'] && post._embedded['wp:featuredmedia'][0].source_url && (
                    <div className="projectBrief-card-image-wrapper">
                        <img
                            src={post._embedded['wp:featuredmedia'][0].source_url}
                            alt={post._embedded['wp:featuredmedia'][0].alt_text || post.title.rendered}
                            className="projectBrief-card-image"
                        />
                    </div>
                )}

                <div className="projectBrief-card-content">
                    <Link to={`/post/${post.slug}`} className="projectBrief-postLink" aria-labelledby={`post-title-${post.id}`}>
                        <h4 id={`post-title-${post.id}`} className="projectBrief-card-title">{post.title.rendered}</h4>
                    </Link>
                    <div className="projectBrief-card-text">{post.excerpt.rendered}</div>
                </div>
            </Tilt>
        </motion.article>
    );
};

export default ProjectBrief;









/*

This is  ProjectBrief component that represents an individual post in a summarized format, you can define a new component that accepts post details as props. This component can then be used within your Portfolio component to represent each post.

*/



