import React from 'react';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const DefaultIcon = () => (
  // This is a simple SVG that will be displayed when no icon is available
  <svg height="40" width="40">
    <circle cx="20" cy="20" r="15" stroke="black" strokeWidth="2" fill="grey" />
  </svg>
);

const ExperienceCard = ({ experience }) => (
  <VerticalTimelineElement
    date={experience.date}
    dateClassName="experience-date"
    iconClassName="experience-icon"
    contentStyle={{
      background: '#eaeaec',
      color: '#292929',
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    }}
    contentArrowStyle={{
      borderRight: '7px solid  #232631',
    }}
    iconStyle={{ background: '#333333', boxShadow: 'none' }}
    // icon={experience.iconBg 
    //   ? <img src={experience.iconBg} alt={`${experience.company_name} logo`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
    //   : <DefaultIcon/>
    // }
    // icon={
    //   experience.iconBg 
    //   ? <img src={`/assets/other/${experience.iconBg}`} alt={`${experience.company_name} logo`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
    //   : <DefaultIcon/>
    // }
    // role="article"
    // aria-label={`Work experience at ${experience.company_name}`}
    icon={
      experience.iconBg 
        ? <img src={experience.iconBg} alt={`${experience.company_name} logo`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        : <DefaultIcon/>
    }
  >
    <div>
      <h3 className="experience-job-title">{experience.job_position}</h3>
      <p className="experience-company-name">{experience.company_name}</p>
      <a
        href={experience.company_website}
        className="experience-website-link"
        target="_blank"
        rel="noopener noreferrer"
        aria-label={`Visit ${experience.company_name} website`}
      >
        visit website
      </a>
    </div>
  </VerticalTimelineElement>
);

export default ExperienceCard;























