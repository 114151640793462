import React from 'react';
import styles from '../Styles/component-styles/footer.css';

const Footer = () => {
  
  const currentYear = new Date().getFullYear();

  
  return (
    <footer role="contentinfo" aria-label="Footer Content" className={styles.footer}>
      <section aria-label="Copyright Section">
        <div aria-labelledby="copyright-header">
          <span className='copyright-header'>
            Massa Sakou, Full Stack Web Developer.<br /> {currentYear} &copy; All rights reserved.
          </span>
        </div>
      </section>

      <section aria-label="Contact Section">
        <address aria-labelledby="contact-header">
          <ul>
            <li>
              <a 
                href="https://www.linkedin.com/in/msakoufwd" 
                aria-label="Visit LinkedIn profile" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                LinkedIn
              </a>
            </li>
            <li>
              <a 
                href="https://github.com/pema37"
                aria-label="Visit Github profile"
                target="_blank"
                rel="noopener noreferrer"
              >
                Github
              </a>
            </li>
            <li>
              <a 
                href="mailto:msakou@my.bcit.ca" 
                aria-label="Send email"
              >
                Email Me
              </a>
            </li>
          </ul>
        </address>
      </section>
    </footer>
  );
};

export default Footer;


