import React from "react";
import SkillCarouselContainer from './SkillCarouselContainer';
import '../../Styles/my-skills-styles/MySkillsContainer.css';

function MySkillsContainer() {
  return (
    <section className="my-skills-container">
      <SkillCarouselContainer />
    </section>
  );
}

export default MySkillsContainer;
