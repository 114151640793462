import React from 'react';
import { Link } from 'react-router-dom'; 
import { motion } from 'framer-motion'; 
import Tilt from 'react-parallax-tilt';
import { fadeIn, zoomIn } from '../utilities/MyAnimation';
import '../Styles/component-styles/postCard.css';

const PostCard = ({ post, index }) => {
  return (
      <motion.article
          key={post.id}
          id={`post-${post.id}`}
          variants={{ ...fadeIn('up', 'spring', index * 0.5, 0.75), ...zoomIn(index * 0.5, 0.75) }}
          className="post-card"
          role="article"
      >
          <Tilt
              options={{
                  max: 40,
                  scale: 1,
                  speed: 450
              }}
          >
              {post._embedded && post._embedded['wp:featuredmedia'] && post._embedded['wp:featuredmedia'][0].source_url && (
                  <div className="post-card-image-wrapper">
                      <img 
                          src={post._embedded['wp:featuredmedia'][0].source_url} 
                          alt={post._embedded['wp:featuredmedia'][0].alt_text || post.title.rendered} 
                          className="post-card-image"
                      />
                  </div>
              )}
              
              <div className="post-card-content">
                  <Link to={`/post/${post.slug}`} className="post-card-title-link" aria-labelledby={`post-title-${post.id} post-author-${post.id}`}>
                      <h4 id={`post-title-${post.id}`} className="post-card-title">{post.title.rendered}</h4>
                  </Link>
                  <div className="post-card-text">{post.excerpt.rendered}</div>
              </div>
          </Tilt>
      </motion.article>
  );
};

export default PostCard; 









/*
PostCard (only used in the Home page)

  Purpose: Represents an individual post in a summarized format, usually used within a list.

  Behavior:
    It takes a post object as a prop and displays its key information, like the title, excerpt, and featured image.
    Designed to be a reusable component to show a post's preview.

  Key Features:
    Displaying the featured image of the post.
    Showing the post's title and excerpt.
    Linking to the detailed post page using the post's slug.

*/







