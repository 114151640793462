import React, { useState } from 'react';
// import  '../Styles/component-styles/cta.css';

function CTA({ restData }) {
  const [showEmail, setShowEmail] = useState(false);

  const handleEmailButtonClick = () => {
    setShowEmail(true);
  };

  const handleCopyToClipboard = () => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(restData?.acf?.lets_connect)
        .then(() => {
          console.log('Email address copied to clipboard!');
        })
        .catch(err => {
          console.log('Something went wrong', err);
        });
    } else {
      console.log('Your browser does not support the clipboard API');
    }
  };

  return (
    <div>
      {
        restData?.acf?.lets_connect && (
          <>
            {!showEmail && 
              <button 
                onClick={handleEmailButtonClick}
                aria-label="Connect And Show My Email" 
                title="Connect And Show My Email"
              >
                Show My Email !
              </button>
            }
            
            { 
              showEmail &&
              <div>
                <a href={`mailto:${restData?.acf?.lets_connect}?subject=Website inquiry`} 
                  aria-label={`Send me an email at ${restData?.acf?.lets_connect}`} 
                  title={`Send me an email at ${restData?.acf?.lets_connect}`}>
                  {restData.acf.lets_connect}
                </a>
                <br/>
                <button onClick={handleCopyToClipboard}>Copy to Clipboard</button>
              </div>
            }
          </>
        )
      }
    </div>
  );
}

export default CTA;









