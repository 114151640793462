// Check if the user has set their system to use reduced motion
const prefersReducedMotion = window.matchMedia("(prefers-reduced-motion: reduce)").matches;


// Fade-in animation
export const fadeIn = (direction, type, delay, duration) => {
  duration = prefersReducedMotion ? 0 : duration;
  return {
    hidden: {
      x: direction === 'left' ? 100 : direction === 'right' ? -100 : 0,
      y: direction === 'up' ? 100 : direction === 'down' ? -100 : 0,
      opacity: 0,
      transitionEnd: { visibility: 'hidden' }
    },
    show: {
      x: 0, y: 0, opacity: 1,
      transition: { type, delay, duration, ease: 'easeOut' },
      transitionEnd: { visibility: 'visible' }
    }
  };
}

// For image to slide from left to right
export const slideInFromLeft = (type, delay, duration) => {
  duration = prefersReducedMotion ? 0 : duration;
  return {
    hidden: { opacity: 0, x: '-20vw' },
    show: { opacity: 1, x: 0, transition: { type, delay, duration, ease: 'easeOut' } }
  };
}

// For text to slide from right to left
export const slideInFromRight = (type, delay, duration) => {
  duration = prefersReducedMotion ? 0 : duration;
  return {
    hidden: { opacity: 0, x: '20vw' },
    show: { opacity: 1, x: 0, transition: { type, delay, duration, ease: 'easeOut' } }
  };
}

// Generic slide-in animation
export const slideIn = (direction, type, delay, duration) => {
  duration = prefersReducedMotion ? 0 : duration;
  return {
    hidden: {
      x: direction === 'left' ? '-100%' : direction === 'right' ? '100%' : 0,
      y: direction === 'up' ? '100%' : direction === 'down' ? '-100%' : 0,
    },
    show: {
      x: 0, y: 0,
      transition: { type, delay, duration, ease: 'easeOut' },
    }
  };
}

// Handles the staggering of child animation
export const staggerContainer = (staggerChildren, delayChildren) => {
  return {
    hidden: {},
    show: { transition: { staggerChildren, delayChildren: delayChildren || 0 } }
  };
}

// Handles text variant animations
export const textVariant = (delay, duration) => {
  duration = prefersReducedMotion ? 0 : duration;
  return {
    hidden: { y: -50, opacity: 0 },
    show: {
      y: 0, opacity: 1,
      transition: { type: 'spring', duration, delay },
    }
  };
}

// Handles zoom-in animation
export const zoomIn = (delay, duration) => {
  duration = prefersReducedMotion ? 0 : duration;
  return {
    hidden: { scale: 0, opacity: 0 },
    show: {
      scale: 1, opacity: 1,
      transition: { type: 'tween', delay, duration, ease: 'easeOut' }
    },
  };
}

// Handles fade-in/out animation
export const fadeInOut = (duration) => {
  duration = prefersReducedMotion ? 0 : duration;
  return {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: { duration, ease: 'easeIn' },
    },
    exit: {
      opacity: 0,
      transition: { duration, ease: 'easeOut' },
    }
  };
}
