import FigmaIcon from '../svg/skills/figma-skill-icon.svg';
import HTMlIcon from '../svg/skills/html-skill-icon.svg';
import CSSIcon from '../svg/skills/css-skill-icon.svg';
import SassIcon from '../svg/skills/sass-skill-icon.svg';
import TailwindIcon from '../svg/skills/tailwind-skill-icon.svg';
import VuetifyIcon from '../svg/skills/vuetify-skill-icon.svg';
import JavaScriptIcon from '../svg/skills/js-skill-icon.svg';
import NodeIcon from '../svg/skills/node-skill-icon.svg';
import VueIcon from '../svg/skills/vue-skill-icon.svg';
import ReactIcon from '../svg/skills/react-skill-icon.svg';
import RubyIcon from '../svg/skills/ruby-skill-icon.svg';
import RailsIcon from '../svg/skills/rails-skill-icon.svg';
import GitIcon from '../svg/skills/git-skill-icon.svg';
import SQLIcon from '../svg/skills/sql-skill-icon.svg';


const ArrayOfSkills = [
  {
    name: "Figma",
    src: FigmaIcon,
    alt: "Figma icon",
    docLink: "https://www.figma.com/",
  },
  {
    name: "HTML",
    src: HTMlIcon,
    alt: "HTML icon",
    docLink: "https://developer.mozilla.org/en-US/docs/Web/HTML",
  },
  {
    name: "CSS",
    src: CSSIcon,
    alt: "CSS icon",
    docLink: "https://developer.mozilla.org/en-US/docs/Web/CSS",
  },
  {
    name: "SASS",
    src: SassIcon,
    alt: "SASS icon",
    docLink: "https://sass-lang.com/",
  },
  {
    name: "Tailwind",
    src: TailwindIcon,
    alt: "Tailwind icon",
    docLink: "https://tailwindcss.com/",
  },
  {
    name: "Vuetify",
    src: VuetifyIcon,
    alt: "Vuetify icon",
    docLink: "https://vuetifyjs.com/",
  },
  {
    name: "JS",
    src: JavaScriptIcon,
    alt: "JavaScript icon",
    docLink: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
  },
  {
    name: "Node",
    src: NodeIcon,
    alt: "Node icon",
    docLink: "https://nodejs.org/",
  },
  {
    name: "Vue",
    src: VueIcon,
    alt: "Vue icon",
    docLink: "https://v3.vuejs.org/",
  },
  {
    name: "React",
    src: ReactIcon,
    alt: "React icon",
    docLink: "https://reactjs.org/",
  },
  {
    name: "Ruby",
    src: RubyIcon,
    alt: "Ruby icon",
    docLink: "https://www.ruby-lang.org/en/",
  },
  {
    name: "Rails",
    src: RailsIcon,
    alt: "Rails icon",
    docLink: "https://rubyonrails.org/",
  },
  {
    name: "Git",
    src: GitIcon,
    alt: "Git icon",
    docLink: "https://git-scm.com/",
  },
  {
    name: "SQL",
    src: SQLIcon,
    alt: "SQL icon",
    docLink: "https://www.sql.org/",
  },
];

export default ArrayOfSkills;
