import React from 'react';
import '../Styles/utilities-styles/pagination.css';


const Pagination = ({ currentPage, totalPages, setCurrentPage }) => {
  return (
    <nav role="navigation" aria-label="Pagination Navigation">
      <div className="pagination-controls">
        <button 
          disabled={currentPage <= 1}
          onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))}
          aria-label={`Go to page ${currentPage - 1}`}
        >
          Previous
        </button>
        <span>Page {currentPage} of {totalPages}</span>
        <button 
          disabled={currentPage >= totalPages}
          onClick={() => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))}
          aria-label={`Go to page ${currentPage + 1}`}
        >
          Next
        </button>
      </div>
    </nav>
  );
};

export default Pagination;



