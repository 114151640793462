import React, { useState, useEffect } from 'react';

const Typewriter = ({ words = [], loop = false }) => {
  const [subIndex, setSubIndex] = useState(0);
  const [reverse, setReverse] = useState(false);
  const [flash, setFlash] = useState(false);

  useEffect(() => {
    if (subIndex === words[0]?.length + 1 && !reverse && loop) {
      setFlash(true);
      setTimeout(() => {
        setFlash(false);
        setReverse(true);
      }, 1000);
      return;
    }

    if(subIndex === 0 && reverse) {
      setReverse(false);
      return;
    }

    const timeout = setTimeout(() => {
      setSubIndex((prevSubIndex) => prevSubIndex + (reverse ? -1 : 1));
    }, 150);

    return () => clearTimeout(timeout);
  }, [subIndex, reverse, loop, words]);

  return (
    <>
      <span className={`typewritten-text ${flash ? 'flash' : ''}`}>
        {`${words[0]?.substring(0, subIndex)}${subIndex === words[0]?.length ? ' ' : ''}`}
      </span>
    </>
  );
};

export default Typewriter;




