import React from 'react';
import Skill from "../my-skills/Skill";
import ArrayOfSkills from '../../assets/arrays/ArrayOfSkills';
import '../../Styles/my-skills-styles/SkillList.css'; 

function SkillList() {
  return (
    <div className="skill-list"> 
      {ArrayOfSkills.map((skill, index) => (
        <Skill key={index} skill={skill} />
      ))}
    </div>
  );
}

export default SkillList;


