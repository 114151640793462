import React from 'react';
import LottieComponent from '../lottieAnimation/LottieComponent';
import loadingAnimation from '../lottieAnimation/loadingAnimation.json';

const Loading = () => {
  return (
    <div style={loadingContainerStyle}>
      <LottieComponent 
        animationData={loadingAnimation} 
        height={400} 
        width={400} 
      />
    </div>
  );
};

const loadingContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh' 
};

export default Loading;
